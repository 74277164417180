














































































import { Component, Mixins } from 'vue-property-decorator';
import ElePropertiesMixins from './mixins/ElePropertiesMixins';

/**
 * input的配置项
 */
@Component({
  name: 'NormalListConfig',
  components: {}
})
export default class extends Mixins(ElePropertiesMixins) {
  spans: any = [8]; //列表组件的分栏
  columnConfigList: any = [];
  defaultFlag: boolean = true;

  created() {
    // 获取要展示的数据
    // 注意：widget.dataSource的获取 可以放在created中; 通常情况下 dataSource在组件加载前会被获取到
    // 如果created 中无法获取到 可以考虑加入watch
    // const dataSource = this.widget.dataSource.dataSourceConfig?.columnConfig?.customComplexColumns;
    // if (dataSource && dataSource.length) {
    //   this.defaultFlag = false;
    //   this.columnConfigList = dataSource.filter(item => item.display);
    // }

    //获取分栏
    if (Array.isArray(this.widget.properties.spans) && this.widget.properties.spans.length) {
      this.spans = this.widget.properties.spans;
    }
  }

  addColumn() {
    this.spans.push(8);
    this.$set(this.widget.properties, 'spans', this.spans);
  }
  removeColumn(index) {
    this.spans.splice(index, 1);
    this.$set(this.widget.properties, 'spans', this.spans);
  }
}
